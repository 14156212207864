@import '../../theme.scss';

.virals-page {
    display: flex;
    flex: 1;
    height: 100%;
    .scroll-area {
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
    }
    h2 {
        font-size: 15px;
        line-height: 23px;
    }
    .viral-list {
        .viral-container {
            cursor: pointer;
            font-size: 15px;
            font-weight: 900;
            border-bottom: 1px solid #F0F1F2;
            height: 77px;
            &:hover, &.selected {
                background-color: #F4F6F7;
            }
        }
    }
    .content-area {
        flex: 1;
        display: flex;
        overflow-y: auto;
        .viral-content {
            max-width: 100%;
            flex: 1;
            .viral-header {
                height: 40px;
                border-bottom: 1px solid #E7EBEE;
                justify-content: space-between;
                h4 {
                    font-size: 15px;
                }
                .viral-description {
                    width: 22px;
                    height: 22px;
                    border: 1px solid #97ABB5;
                    border-radius: 50%;
                    color: #97ABB5;
                    cursor: pointer;
                    position: relative;
                    &:hover {
                        .description-text {
                            display: block;
                        }
                    }
                    p {
                        font-weight: bold;
                        font-size: 14px;
                    }
                    .description-text {
                        position: absolute;
                        display: none;
                        top: 22px;
                        font-size: 13px;
                        line-height: 20px;
                        color: $main-bg;
                        right: 0;
                        width: fit-content;
                        padding: 5px 15px;
                        white-space: nowrap;
                        border: 1px solid #E9EBEE;
                        border-radius: 5px;
                        background: white;
                        box-shadow: 0 2px 4px #F0F1F2;
                        max-width: 300px;
                        min-width: 150px;
                        white-space: normal;
                        overflow-wrap: break-word;
                    }
                }
            }
            .viral-details {
                flex: 1;
                h2 {
                    font-size: 23px;
                    margin-bottom: 20px;
                    text-align: center;
                }
                .btn-area {
                    flex-wrap: wrap;
                    .sub-btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        border: 1px solid #E3E8EC;
                        border-radius: 6px;
                        cursor: pointer;
                        font-size: 18px;
                        font-weight: 500;
						margin-bottom: 15px;
						color: $main-bg;
                        img {
                            margin-right: 12px;
                        }
                    }
                    .sub-area {
                        display: flex;
                    }
                }
                p {
                    font-size: 18px;
                    text-align: center;
                    font-weight: 500;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .virals-page {
        .viral-list {
            width: 400px;
            padding: 0 30px;
            .viral-container {
                margin: 0 -30px;
                padding-left: 30px;
                padding-right: 30px;
            }
        }
        .content-area {
            width: calc(100% - 400px);
            box-shadow: -4px 0 30px rgba(0, 0, 0, 0.13);
            padding: 0 30px;
            .viral-details {
                .btn-area {
                    &.area-2, &.area-3 {
                        max-width: 380px;
                        width: 100%;
                    }
                    &.area-5 {
                        max-width: 365px;
                        width: 100%;
                        .sub-btn {
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                    .sub-btn {
                        min-width: 175px;
                        margin-right: 15px;
                        &.big-btn {
                            min-width: 100%;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .virals-page {
        .viral-list {
            width: 100%;
            padding: 0 15px;
            display: none;
            &.activate {
                display: flex;
            }
        }
        .content-area {
            width: 100%;
            display: none;
            padding: 0 15px;
            &.activate {
                display: flex;
            }
            .viral-details {
                .btn-area {
                    width: 100%;
                    .sub-btn {
                        width: 100%;
                    }
                    .sub-area {
                        flex-direction: column;
                        width: 100%;
                    }
                }
            }
        }
    }
}