@import '../../theme.scss';

.blog-page {
    display: flex;
    flex: 1;
    height: 100%;
    .scroll-area {
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
    }
    h2 {
        font-size: 15px;
        line-height: 23px;
    }
    .blog-container {
        cursor: pointer;
        .blog {
            padding: 15px 0;
            border-bottom: 1px solid #F0F1F2;
            display: flex;
            .avatar {
                margin-right: 15px;
            }
            .info {
                flex: 1;
                min-height: 45px;
                .name-area {
                    justify-content: space-between;
                    p {
                        font-size: 11px;
                        color: #7B88A0;
                    }
                    
                    &.has-new {
                        h4 {
                            font-weight: 900;
                        }
                        p {
                            font-weight: 900;
                            color: $primary-text;
                        }
                    }
                }
                h4 {
                    font-size: 15px;
                    font-weight: 900;
                    span {
                        font-size: 11px;
                        color: #7B88A0;
                        font-weight: normal;
                        margin-left: 7px;
                    }
                }
                .blog-details {
                    font-size: 13px;
                    color: #7B88A0;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin-top: 8px;
                    .detail-value {
                        margin-right: 35px;
                        color: $main-bg;
                        text-transform: capitalize;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        &:hover, &.selected {
            background-color: #F4F6F7;
        }
    }
    .blog-list {
        .search-contact {
            height: 50px;
            border: none;
            border-bottom: 1px solid #F0F1F2;
            padding: 0;
            svg, ::placeholder {
                fill: #7B88A0;
                color: #7B88A0;
            }
            .input-text {
                font-size: 13px;
            }
        }
        .filter-area {
            height: 50px;
            justify-content: space-between;
            border-bottom: 1px solid #F0F1F2;
            .box-select {
                // min-width: 560px;
                .selectbox-container, .selectbox2-container {
                    padding-left: 0;
                    background-color: white;
                    p {
                        font-size: 13px;
                        color: #97ABB5;
                    }
                }
                .options {
                    border: none;
                }
                .option {
                    font-size: 13px;
                }
            }
            .new-message {
                fill: $primary-color;
                color: $primary-color;
                cursor: pointer;
                font-size: 13px;
                .svg-icon {
                    margin-right: 7px;
                }
            }
        }
        .blogs {
            flex: 1;
        }
    }
    .content-area {
        flex: 1;
        .content-header {
            border-bottom: 1px solid #F0F1F2;
            font-size: 15px;
            font-weight: 900;
            padding: 10px 0;
        }
        .content-edit {
            padding-top: 10px;
            .guide-area {
                justify-content: space-between;
                height: 50px;
                border-bottom: 1px solid #F0F1F2;
                .svg-icon {
                    fill: $primary-color;
                    svg {
                        width: 16px !important;
                        height: 16px !important;
                    }
                }
            }
            .option-group {
                min-height: fit-content;

                .option-select {
                    .selectbox-container, .selectbox2-container {
                        background-color: white;
                        border: 2px solid #E3E8EC;
                        height: 40px;
                        padding: 0 15px;
                        svg {
                            fill: $primary-color;
                        }
                    }
                    .options {
                        border-width: 2px;
                        .option {
                            min-height: 40px;
                            padding-left: 15px;
                        }
                    }
                }

                .type-item {
                    cursor: pointer;
                    height: 40px;
                    border: 2px solid #E7EBEE;
                    border-radius: 4px;
                    text-align: center;
                    &.active {
                        background-color: $primary-text;
                        color: white;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }

                ::placeholder {
                    color: rgba(32, 54, 98, 0.4);
                }

                .option-note {
                    border: 2px solid #E3E8EC;
                    font-size: 15px;
                    height: 140px;
                    border-radius: 4px;
                }

                textarea {
                    border: 2px solid #E3E8EC;
                    padding: 11px 17px;
                    resize: none;
                    border-radius: 4px;
                }
            }
            .option-container {
                margin-top: 26px;
                display: flex;
                &.btn-container {
                    margin-top: 0;
                }
            }
            .no-image {
                width: 115px;
                height: 115px;
                background: #F4F6F7;
                border-radius: 4px;
                .svg-icon {
                    cursor: pointer;
                    fill: $primary-color;
                    svg {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
            label {
                width: fit-content;
            }
            .image-container {
                img {
                    width: 115px;
                    height: 115px;
                    border-radius: 4px;
                }
                p {
                    cursor: pointer;
                    margin-top: 5px;
                    color: $primary-color;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .blog-page {
        .blog-list {
            width: 400px;
            padding: 0 30px;
            .blog-container {
                margin: 0 -30px;
                padding: 0 30px;
            }
            .blogs {
                margin: 0 -30px;
                padding: 0 30px;
            }
        }
        .content-area {
            width: calc(100% - 400px);
            box-shadow: -4px 0 30px rgba(0, 0, 0, 0.13);
            padding: 0 30px;
            .option-group {
                margin-bottom: 17px;
                .option-title {
                    margin-bottom: 6px;
                }
                
                .type-item {
                    width: 167px;
                    margin-right: 15px;
                }

                .option-note {
                    padding: 8px 13px;
                }

                textarea {
                    height: 100px;
                }
            }
            .option-container {
                justify-content: space-between;
                .option-group, .btn-2 {
                    width: calc(50% - 15px);
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .blog-page {
        .blog-list {
            width: 100%;
            padding: 0 15px;
            display: none;
            &.activate {
                display: flex;
            }
            .blogs {
                margin: 0 -15px;
                padding: 0 15px;
            }
        }
        .content-area {
            width: 100%;
            display: none;
            padding: 0 15px;
            &.activate {
                display: flex;
            }
            .content-header {
                text-align: center;
            }
            .guide-area {
                min-height: 50px;
            }
            .option-group {
                width: 100%;
                margin-bottom: 12px;
                min-height: fit-content;
                .option-title {
                    margin-bottom: 10px;
                }

                .type-item {
                    width: calc(50% - 7px);
                    margin-right: auto;
                }

                .option-note {
                    padding: 11px 16px;
                }

                textarea {
                    height: 100px;
                }
            }
            .option-container {
                flex-direction: column;
                min-height: fit-content;
                .btn-2 {
                    margin-bottom: 15px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}