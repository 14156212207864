@import '../../theme.scss';

.coursedetail-component {
    width: 100%;
    height: 100%;
    max-height: 100%;
    .detail-header {
        height: 70px;
        .container {
            height: 100%;
            max-width: 100%;
            justify-content: space-between;
        }
        .back-btn {
            .svg-icon {
                fill: $secondary-color;
                margin-right: 11px;
                cursor: pointer;
            }
            h1 {
                font-size: 24px;
                line-height: 24px;
                text-transform: capitalize;
            }
        }
        .header-link {
            color: $secondary-color;
            p {
                font-weight: 500;
                margin-right: 10px;
            }
            .svg-icon {
                fill: $secondary-color;
            }
        }
        .btn-link {
            width: 144px;
            height: 39px;
            font-size: 12px;
            line-height: 24px;
        }
    }
    .owl-theme {
        .owl-stage-outer {
            .owl-stage {
                display: flex;
            }
        }
        .owl-nav {
            position: absolute;
            display: flex;
            justify-content: space-between;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
            left: -20px;
            right: -20px;
            pointer-events: none;
            .owl-prev, .owl-next {
                pointer-events: auto;
                outline: none;
                
                div {
                    width: 30px !important;
                    height: 30px !important;
                    img {
                        width: 10px !important;
                    }
                }
                &:hover {
                    background: none;
                }
            }
        }
    }
    .selected_img {
        padding-top: 56%;
        margin-bottom: 15px;
        img {
            border-radius: 10px;
            cursor: pointer;
        }
    }
    .thumb-container {
        padding-top: 100%;
    }
    .thumb {
        filter: opacity(0.6);
        border-radius: 10px;
        &.active {
            filter: opacity(1);
            border: 2px solid #5CD4C9;
        }
    }
    .main-content {
        max-height: calc(100% - 70px);
        // height: calc(100% - 70px);
        align-items: flex-start;
        overflow-y: auto;
        display: flex;
        background: #F6F5F9;
        .course-content {
            background: white;
            min-height: fit-content;
            .content-group {
                margin-bottom: 34px;
                h2 {
                    padding-bottom: 25px;
                    border-bottom: 1px solid #E7E4F3;
                }
                .group-body {
                    padding-top: 24px;
                    .description {
                        margin-bottom: 22px;
                    }
                    .learn-item {
                        margin-bottom: 18px;
                        .svg-icon {
                            fill: #712ABC;
                            margin-right: 9px;
                        }
                    }
                    .show-more-btn {
                        width: fit-content;
                        color: $secondary-color;
                        margin-left: 26px;
                        cursor: pointer;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .course-summary {
            background: #F6F5F9;
            min-height: fit-content;
            .sub-summary-title {
                text-transform: capitalize;
            }
            .summary-p {
                margin-bottom: 16px;
            }
            .summary-group {
                padding: 28px 0 22px;
                border-top: 1px solid #E7E4F3;
                min-height: fit-content;
                h3 {
                    font-size: 16px;
                    margin-bottom: 10px;
                }
                .summary-item {
                    margin-bottom: 6px;
                    .first-item {
                        fill: #5F46C1;
                        margin-right: 6px;
                        &.circle {
                            width: 5px;
                            height: 5px;
                            background: #5F46C1;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    p {
                        text-transform: capitalize;
                    }
                }
            }
        }
        .image-container {
            position: relative;
            margin-top: 15px;
            img {
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
            }
        }
        .raw-text {
            white-space: pre-line;
        }

    }
}

@media screen and (min-width: 992px) {
    .coursedetail-component {
        .container {
            width: 100%;
            padding: 0 30px;
        }
        .detail-header {
            background: white;
            box-shadow: 0px 4px 45px rgba(42, 26, 127, 0.07);
        }
        .main-content {
            h2 {
                font-size: 20px;
                line-height: 24px;
            }
            .course-content {
                flex: 1;
                padding: 26px 16px 36px 0;
                max-width: calc(100% - 366px);
            }
            .course-summary {
                width: 366px;
                padding-top: 26px;
                .image-container {
                    padding-top: 75%;
                    margin: 25px 0;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .coursedetail-component {
        .container {
            width: 100%;
            max-width: 100%;
            padding: 0 20px;
        }
        .detail-header {
            background: #F6F5F9;
        }
        .main-content {
            flex-direction: column-reverse;
            h2 {
                font-size: 18px;
                line-height: 24px;
            }
            .course-content {
                padding: 42px 0 64px;
                width: 100%;
            }
            .course-summary {
                padding-bottom: 22px;
                width: 100%;
                .image-container {
                    padding-top: 56%;
                    margin: 25px 0;
                }
                &.give-padding {
                    padding-top: 26px;
                }
            }
        }
    }
}