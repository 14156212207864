@import '../../theme.scss';

@keyframes courseSidebarShow {
    0% {  transform: translateX(100%); }
    100% { transform: translateX(0); }
}
  
@keyframes courseSidebarHide {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0); }
}

.coursesidebar-component {
    .coursesidebar-wrapper {
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 20;
        background: none;
        height: 100vh;
        font-size: 15px;
        color: $primary-color;
        display: flex;
        flex-direction: row-reverse;
        pointer-events: none;
        .coursesidebar-block {
            flex: 1;
            height: 100%;
            display: none;
            pointer-events: auto;
            background: rgba(12, 2, 29, 0.7);
        }
        .coursesidebar-content {
            width: 100%;
            max-width: 1117px;
            margin-right: -100%;
            height: 100%;
            max-height: 100%;
            background: white;
            pointer-events: auto;
            display: flex;
            flex-direction: column;
        }
    }

    &.active {
        .coursesidebar-block {
            display: block;
        }
        .coursesidebar-content {
            margin-right: 0;
            animation: courseSidebarShow 0.2s ease-out;
        }
    }
    
    &.inactive {
        .coursesidebar-block {
            display: none;
        }
        .coursesidebar-content {
            margin-right: -100%;
            animation: courseSidebarHide 0.2s ease-out;
        }
    }
}