@import '../../theme.scss';

.settings-page {
    height: 100%;

    .settings-container {
        max-width: 530px;        
        &.availability-container {
            max-width: 100%;
        }
    }

    p {
        font-size: 15px;
        line-height: 24px;
	}
	
	.save-btn {
        width: 100%;
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
	}

    .custom-flag {
        padding: 0;
        margin: 0;
        outline: none;
        .flag-select__btn {
            display: flex;
            align-items: center;
            outline: none;
        }	
        .flag-select__option {
            padding: 0;
            margin: 0;
        }
        .selected--flag--option {
                padding: 0;
        }
        img {
                top: 0;
        }
    }
	
	.option-select {
		.selectbox-container {
			background-color: white;
			border: 2px solid #E3E8EC;
			height: 40px;
			padding: 0 15px;
			svg {
				fill: $primary-color;
			}
		}
		.options {
			border-width: 2px;
			.option {
				height: 40px;
				padding-left: 15px;
			}
		}
	}

    .settings-header {
        border-bottom: 1px solid #F0F1F2;

        .head-menu {
            cursor: pointer;
            color: $primary-color;
            font-size: 11px;
            padding-bottom: 11px;
            font-weight: 900;
            white-space: nowrap;
            &.active {
                color: $primary-text;
                border-bottom: 1px solid $primary-text;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .settings-body {
        flex: 1;
        overflow-y: auto;

        h2 {
            font-size: 15px;
            line-height: 23px;
        }

        #section-profile {
            padding-top: 39px;
        }

        #section-info, #section-password {
            .btn-2 {
                margin-top: 5px;
            }
        }

        #section-how-it {
            .accordion {
                border-top: 1px solid #E7EBEE;
                &:last-child {
                    border-bottom: 1px solid #E7EBEE;
                }
            }
        }

        .option-group {
            margin-bottom: 25px;

            .option-select {
                .selectbox-container {
                    background-color: white;
                    border: 2px solid #E3E8EC;
                    height: 40px;
                    padding: 0 15px;
                    svg {
                        fill: $primary-color;
                    }
                }
                .options {
                    border-width: 2px;
                    .option {
                        height: 40px;
                        padding-left: 15px;
                    }
                }
            }

            ::placeholder {
                color: rgba(32, 54, 98, 0.4);
            }

            .option-note {
                border: 2px solid #E3E8EC;
                font-size: 15px;
                border-radius: 4px;
                resize: none;
            }

            .photo-area {
                .avatar {
                    width: 75px;
                    height: 75px;
                    border-radius: 50%;
                    margin-right: 22px;
                }
                label {
                    margin: 0;
                }
                .upload_btn {
                    color: $main-bg;
                    border-color: $main-bg;
                    padding: initial;
                    height: 40px;
                }
            }

            .check-box {
                margin-right: 15px;
            }
        }

        .check-section {
            margin-bottom: 15px;
        }

        .subject-p {
            margin-bottom: 23px;
        }

        .education-p {
            padding-bottom: 23px;
            margin-bottom: 25px;
            border-bottom: 1px solid #F0F1F2;
        }

        .education-group {
            padding-bottom: 5px;
            margin-bottom: 25px;
            border-bottom: 1px solid #F0F1F2;
		}
		
		.qualificationsection-component {
			background: #F4F6F7;
			border-radius: 4px;
			padding: 0 15px 5px 15px;
			margin-bottom: 20px;
			.qualificationsection-name {
				cursor: pointer;
				height: 40px;
				justify-content: space-between;
				.qualificationsection-title {
					font-size: 15px;
					font-weight: 900;
					color: $main-bg;
				}
				.add-btn {
					color: $primary-color;
					cursor: pointer;
				}
			}
			.qualificationsection-table {
				width: 100%;
				th {
					font-size: 13px;
					line-height: 23px;
					&:last-child {
						text-align: right;
					}
				}
				tr {
					height: 45px;
					border-top: 1px solid #E9EBEE;
					border-bottom: 1px solid #E9EBEE;
					border-collapse: collapse;
					td {
						font-size: 13px;
						p {
							font-size: 13px;
							color: $primary-color;
							cursor: pointer;
							width: fit-content;
						}
						.svg-icon {
							width: fit-content;
							margin-left: auto;
							&.completed {
								svg {
									width: 14px;
									fill: #24C1A5;
								}
							}
							&.incompleted {
								transform: rotate(45deg);
								svg {
									width: 14px;
									height: 14px;
									fill: #FF0056;
								}
							}
						}
						&:first-child {
							width: 230px;
						}
						&:last-child {
							text-align: right;
						}
					}
				}
			}
		}

		.checksection-component {
			background: #F4F6F7;
			border-radius: 4px;
			padding: 0 15px;
			margin-bottom: 20px;
			.checksection-name {
				cursor: pointer;
				flex-direction: column;
				justify-content: space-between;
			}
			.checksection-content {
				display: flex;
				flex-wrap: wrap;
				padding-bottom: 5px;
				.checksection-item {
					overflow: hidden;
					padding-right: 5px;
					white-space: nowrap;
					text-overflow: ellipsis;
					height: 30px;
					.check-box {
						min-width: 17px !important;
						width: 17px !important;
						height: 17px !important;
						border-width: 1px;
					}
					p {
						font-size: 15px;
						line-height: initial;
						margin-left: 8px;
					}
                }
                
                .owl-theme {
                    .owl-stage-outer {
                        .owl-stage {
                            display: flex;
                        }
                    }
                }
			}
		}
    }

    .availavbility-section {
        display: flex;
        .pre-setting {
            display: flex;
            .help-area {
                display: flex;
                .help-item {
                    .help-color {
                        width: 14px;
                        height: 14px;
                        border-radius: 2px;
                        margin-right: 7px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        &.available {
                            background: white;
                            border: 1px solid #F0F1F2;
                        }
                        &.unavailable {
                            background: #B3E1F7;
                        }
                    }
                    p {
                        font-size: 13px;
                        text-transform: capitalize;
                    }
                }
            }
            .pre-explain {
                font-size: 13px;
                line-height: 20px;
                color: #7B88A0;
			}
			.save-btn {
				width: 120px;
				font-size: 13px;
				font-weight: normal;
			}
            .delete-all {
                color: $main-bg;
                cursor: pointer;
                width: fit-content;
                p {
                    margin-left: 7px;
                    font-size: 13px;
                }
            }
        }
        .setting-area {
            overflow-x: auto;
            .setting-table {
                width: 100%;
                font-size: 15px;
                .start-col {
                    min-width: 55px;
                    width: 55px;
                }
                .header-col {
                    min-width: 120px;
                    text-align: center;
                    font-weight: normal;
                    p {
                        font-size: 15px;
                        margin-bottom: 19px;
                    }
                    &.active {
                        color: $primary-color;
                    }
                }
                td, th {
                    border: 1px solid #F0F1F2;
                    border-collapse: collapse;
                    margin: 0;
                    padding: 0;
                    &:first-child {
                        border-left: none;
                        cursor: default;
                        padding-right: 8px;
                        text-align: right;
                        color: #7B88A0;
                    }
                }
                th {
                    border-top: none;
                    color: #7B88A0;
                }
                tbody {
                    .unavailable, .booked {
                        border-top: none;
                        border-bottom: none;
                    }
                    td {
                        height: 46px;
                        cursor: pointer;
                    }
                    .inner-schedule {
                        width: calc(100% - 8px);
                        height: 100%;
                        border-radius: 4px;
                        background: white;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .del-btn {
                            width: 23px;
                            height: 23px;
                            background: white;
                            border-radius: 50%;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            box-shadow: 0 2px 6px rgba($color: #000000, $alpha: 0.2);
                            right: -27px;
                            position: absolute;
                            z-index: 10;
                            svg {
                                width: 9px;
                                fill: #7B88A0;
                            }
                        }
                        &.has-prev {
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;
                        }
                        &.has-next {
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                        &.unavailable {
                            background: #B3E1F7;
                        }
                        &.booked {
                            background: #24C1A5;
                            color: white;
                        }
                        &.selected {
                            background: $primary-color;
                            box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.25);
                            color: white;
                        }
                    }
                }
            }
        }
    }
    .agegroup-row {
        border-radius: 8px;
        background: rgba(51, 13, 99, 0.03);
        padding: 5px;
        max-width: 100%;
        overflow-x: auto;
        .age-group {
            padding: 13px;
            border-radius: 8px;
            cursor: pointer;
            font-size: 14px;
            font-weight: bold;
            color: rgba(51, 13, 99, 0.45);
            white-space: nowrap;
            &.active {
                background: white;
                color: $primary-color;
                box-shadow: 0px 4px 15px rgba(42, 26, 127, 0.1);
            }
        }
    }
    .tech-row {
        border-radius: 8px;
        background: rgba(51, 13, 99, 0.03);
        padding: 5px;
        margin-bottom: 10px;
        width: fit-content;
        .tech {
            border-radius: 8px;
            cursor: pointer;
            font-size: 14px;
            font-weight: bold;
            color: rgba(51, 13, 99, 0.45);
            width: 120px;
            height: 40px;
            &.active {
                background: white;
                color: $primary-color;
            }
        }
    } 
}

.coursedetail-component{ color: #203662; }

@media screen and (min-width: 992px) {
    .settings-page {
        padding: 27px 30px 10px 30px;       
        .header-container {
            justify-content: space-between;
        }
        #section-info, #section-password {
            padding-top: 38px;
            p {
                margin-bottom: 34px;
            }
        }
        #section-how-it {
            padding-top: 44px;
        }
        #section-suspend {
            p {
                margin: 38px 0 32px;
            }
        }
        .settings-body {
            .option-group {
                .option-title {
                    margin-bottom: 6px;
                }
                
                .type-item {
                    width: 167px;
                    margin-right: 15px;
                }

                .option-note {
                    padding: 8px 13px;
                    height: 129px;
                }

                .photo-area {
                    .upload_btn {
                        width: 127px;
                    }
                }

                &.picture-option {
                    padding-bottom: 30px;
                    border-bottom: 1px solid #F0F1F2;
                    margin-bottom: 39px;
                }
            }
            .submit-btn {
                margin-bottom: 64px;
			}
        }

        .availavbility-section {
            .pre-setting {
                flex-direction: column;
                min-width: 180px;
                width: 180px;
                margin-right: 40px;
                .help-area {
                    flex-direction: column;
                    .help-item {
                        margin-bottom: 17px;
                    }
                }
                .pre-explain {
                    margin-top: 7px;
                }
                .delete-all {
                    margin: 10px 0;
                }
            }
            .setting-area {
                flex: 1;
            }
		}
		
		.checksection-component {
			.checksection-content {
				.checksection-item {
					width: 50%;
					max-width: 50%;
				}
                .owl-theme {
                    .owl-stage-outer {
                        padding: 25px 0 95px;
                    }
                    .owl-nav {
                        position: absolute;
                        display: flex;
                        justify-content: space-between;
                        top: 40%;
                        margin: 0;
                        left: -30px;
                        right: -30px;
                        pointer-events: none;
                        .owl-prev, .owl-next {
                            pointer-events: auto;
                            outline: none;
                            &:hover {
                                background: none;
                            }
                        }
                    }
                }
			}
		}
    }

}

@media screen and (max-width: 991px) {
    .settings-page {
        padding: 27px 15px 10px 15px;

        .settings-container {
            padding: 0;
        }
        
        .settings-header {
            ::-webkit-scrollbar {
                display: none;
            }
            -ms-overflow-style: none;
            scrollbar-width: none;
        }
        .header-container {
            overflow-x: auto;
            .head-menu {
                margin-right: 25px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        #section-info, #section-password {
            padding-top: 23px;
            p {
                margin-bottom: 19px;
            }
        }
        #section-how-it {
            padding-top: 9px;
        }
        #section-suspend {
            p {
                margin: 24px 0 25px;
            }
        }
        .settings-body {
            .option-group {
                .option-title {
                    margin-bottom: 10px;
                }

                .type-item {
                    width: calc(50% - 7px);
                    margin-right: auto;
                }

                .option-note {
                    padding: 11px 16px;
                    height: 140px;
                }

                .photo-area {
                    label {
                        flex: 1;
                        .upload_btn {
                            width: 100%;
                        }
                    }
                }

                &.check-option {
                    margin-top: -17px;
                }
            }
            .submit-btn {
                margin-bottom: 75px;
			}
        }
        .availavbility-section {
            flex-direction: column;
            .pre-setting {
                flex-direction: column-reverse;
                margin-bottom: 24px;
                .help-area {
                    .help-item {
                        margin-right: 52px;
                        &:last-child {
                            margin: 0;
                        }
                    }
                }
                .pre-explain {
                    margin-bottom: 20px;
				}
				.delete-all {
					margin-left: 20px;
				}
            }
            .setting-table {
                .header-col {
                    p {
                        &:first-child {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
		}
		.checksection-component {
			.checksection-content {
				.checksection-item {
					width: 100%;
					max-width: 100%;
				}
                .owl-theme {
                    .owl-stage-outer {
                        padding: 25px 0 30px;
                    }
                    .owl-nav {
                        margin: 0;
                        .owl-prev, .owl-next {
                            outline: none;
                            &:hover {
                                background: white;
                            }
                        }
                        .owl-prev {
                            margin: 0 15px 0 5px;
                        }
                        .owl-next {
                            margin: 0 5px 0 15px;
                        }
                    }
                }
			}
		}
    }

}