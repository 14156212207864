@import '../../theme.scss';

.selectbox2-component {
    min-width: 53px;
    position: relative;
    cursor: pointer;

    .selectbox2-container {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        outline: none;
        background-color: #F1F3F4;
        height: 30px;
        border-radius: 3px;
        font-size: 13px;
        color: #203662;
        padding: 0 7px;
        .start-icon {
            fill: #C5CBD7;
            margin-right: 11px;
        }

        p {
			flex: 1;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
        }
        .placeholder {
            color: rgba(32, 54, 98, 0.4);
        }
        svg {
            fill: #ADB1B5;
            margin-left: 5px;
        }
    }

    ::-webkit-scrollbar-track {
        background: white;
    }

    .options {
        min-width: 100%;
        background: white;
        display: flex;
        flex-direction: column;
        border-left: 1px solid #E7EBEE;
        border-right: 1px solid #E7EBEE;
        border-bottom: 1px solid #E7EBEE;
        position: absolute;
        z-index: 15;
        max-height: 200px;
        overflow-y: auto;

        .option {
            display: flex;
            align-items: center;
            color: #203662;
            background: white;
			min-height: 30px;
			padding: 5px 0 5px 7px;
            width: 100%;

            &:hover {
                color: white;
                background: #203662 !important;
            }
        }
    }
}