@import '../../theme.scss';

.course-component {
    width: 100%;
    position: relative;
    padding-top: 20px;
    
    .select-picker{
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: solid 1px $primary-color;
        .check-icon {
            color: $primary-color;
        }
    }

    .right-mark {
        position: absolute;
        right: 0;
        top: 0;
        width: 20px !important;
    }

    .sub-block {
        width: calc(100% - 30px);
        position: relative;
    }

    .thumb-container {
        height: 235px;
        .course-thumb {
            width: 100%;
        }
        .age-container {
            position: absolute;
            background: url('../../Assets/Course/gradebg.png') no-repeat;
            background-size: 100% 100%;
            top: 10px;
            left: -5px;
            width: 120px;
            height: 30px;
            color: white;
            padding-bottom: 5px;
            font-size: 9px;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
    .info-container {
        padding: 30px 24px 0 30px;
        background: white;
        margin: -60px -2px 0 auto;
        box-shadow: 0 0 170px rgba(42, 26, 127, 0.2);
        .header-info {
            p {
                span {
                    color: rgba(51, 13, 99, 0.6);
                }
            }
            .sub-header-info {
                text-overflow: ellipsis;
                overflow: hidden;
                text-transform: capitalize;
            }
        }
        .description {
            line-height: 24px;
            margin: 22px 0 21px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
        }
        .btn-link {
            height: 45px;
            font-size: 14px;
            margin-bottom: 30px;
        }
    }
}

@media screen and (min-width: 992px) {
    .course-component {
        max-width: 370px;
        .course-header {
            .category {
                width: 45px;
                height: 45px;
                margin-right: 14px;
            }
            .header-info {
                max-width: calc(100% - 59px);
                h2 {
                    font-size: 20px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
        .description {
            height: 72px;
        }
        &:hover {
            margin-bottom: -75px;
            .info-container {
                .btn-link {
                    display: flex !important;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .course-component {
        min-width: 340px;
        .thumb-container {
            height: auto;
            padding-top: 70%;
            position: relative;
            .course-thumb {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
            }
        }
        .course-header {
            .category {
                width: 40px;
                height: 40px;
                margin-right: 10px;
            }
            .header-info {
                max-width: calc(100% - 50px);
                h2 {
                    font-size: 18px;
                }
                p {
                    font-size: 12px;
                }
            }
        }
    }
}