@import '../../theme.scss';

.monthlyreport-page {
    display: flex;
    flex: 1;
    height: 100%;
    .scroll-area {
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
    }
    h2 {
        font-size: 15px;
        line-height: 23px;
    }
    .content-area {
        flex: 1;
        display: flex;
        overflow-y: auto;
        .date-area {
            color: #7B88A0;
            p {
                font-size: 16px;
                font-weight: 900;
            }
        }
        .data-area {
            flex: 1;
            .cell-1 {
                width: 100px;
            }
            .cell-2 {
                width: 100px;
            }
            .cell-3 {
                flex: 1;
            }
            .cell-4 {
                width: 25%;
            }
            .data-header {
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
                padding: 0 30px;
                font-size: 11px;
                font-weight: 700;
                .cell {
                    padding: 17px 15px;
                }
            }
            .data-list {
                flex: 1;
                max-height: calc(100% - 50px);
                overflow-y: auto;
                font-size: 15px;
                .data-row {
                    display: flex;
                    padding: 0 30px;
                    border-bottom: 1px solid #EDEEF0;
                    .cell {
                        padding: 15px;
                    }
                }
                .price-row {
                    &:nth-of-type(odd) {
                        background-color: #F1F3F480;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .monthlyreport-page {
        .content-area {
            width: calc(100% - 400px);
            box-shadow: -4px 0 30px rgba(0, 0, 0, 0.13);
            .date-area {
                padding-top: 21px;
            }
            .data-area {
                max-height: calc(100% - 50px);
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .monthlyreport-page {
        .content-area {
            width: 100%;
            display: none;
            &.activate {
                display: flex;
            }
            .date-area {
                padding-top: 12px;
                border-bottom: 1px solid #EDEEF0;
            }
            .data-area {
                max-height: calc(100% - 43px);
                overflow-y: auto;
                .mobile-row {
                    padding: 0 15px;
                    min-height: fit-content;
                    .data-row {
                        padding: 12px 0;
                        flex: 1;
                        display: flex;
                        .col-header {
                            font-weight: 700;
                            font-size: 11px;
                            margin-bottom: 9px;
                        }
                        p {
                            line-height: 23px;
                        }
                        .left-col {
                            width: 40%;
                            padding-right: 5px;
                        }
                        .active {
                            cursor: pointer;
                            color: $primary-color;
                            svg {
                                fill: $primary-color;
                                margin-right: 9px;
                            }
                        }
                        .right-col {
                            flex: 1;
                            font-size: 15px;
                        }
                    }
                    &.price-row {
                        &:nth-of-type(odd) {
                            background-color: #F1F3F480;
                        }
                    }
                }
            }
        }
    }
}